function exportLeads() {
    let searchForm = ZIDX.$(".zidxSearchListViewForm").serializeArray();
    let exportForm = ZIDX.$("#leads_export_form").serializeArray();

    // I assume there is always at least one element in export form
    var url = `/account/leads/export?${exportForm[0].name}=${exportForm[0].value}`
    for (let i = 1; i < exportForm.length; ++i)
        url += `&${exportForm[i].name}=${exportForm[i].value}`
    for (let field of searchForm)
        if (field.name.substring(0, 6) == "search")
            url += `&${field.name}=${field.value}`

    window.open(url);
}

class ZIDXAccountLeads implements ZIDXPage {
    constructor() {

    }

    getPageClass() {
        return "zidxAccountLeadsContainer";
    }

    getMatchURL() {
        return "/account/leads";
    }

    render() {
        // console.log("leads");
        let listView=new ZIDXAccountListView();
        listView.render();
    }
}